import { CountryCode, InterPayList } from '@/api/types/payment';

const atomeInterPay: InterPayList = {
  title: 'Atome',
  src: '',
  key: 'ATOME',
};

const paypalInterPay: InterPayList = {
  title: 'PayPal',
  key: 'PAYPAL',
  src: '/icon/icon-paypal.svg',
  listTips: 'PayPal may charge extra transaction fees, actual amount may differ.',
  remind: '(Extra fees may apply)',
};
// 银联
export const unionInterpay: InterPayList = {
  title: 'Union Pay',
  key: 'UNION_PAY',
  src: '/icon/icon-union.svg',
  // after: <DiscountTips />,
};
// venmo支付
export const venmoInterpay: InterPayList = {
  title: 'Venmo',
  key: 'VENMO',
  src: '/icon/icon-venmo.svg',
  listTips: 'PayPal may charge extra transaction fees, actual amount may differ.',
  remind: '(Extra fees may apply)',
  // after: <DiscountTips />,
};

// Cash App Pay
export const cashAppPayInterpay: InterPayList = {
  title: 'Cash App Pay',
  key: 'CASH_APP',
  src: '/icon/icon_cash_app.svg',
};

export const InstallmentInterPay: InterPayList = {
  title: 'Klarna',
  src: '/icon/icon-installment.svg',
  key: 'KLARNA',
  // newOffer: true,
};
// 新加坡支付
export const payNowInterPay: InterPayList = {
  title: 'Pay Now',
  src: '/icon/icon-paynow.svg',
  key: 'PAY_NOW',
  min: 0,
  max: 4300,
  paramsKey: 'pay_now',
  quotaCurrencys: 'SGD',
};

export const alipayHkPay: InterPayList = {
  title: 'AlipayHK',
  key: 'ALIPAY_HK',
  src: '/icon/icon-alipay-hk.svg',
  paramsKey: 'alipayhk',
  listTips: 'For Hong Kong residents only',
};

export const creditCardPay: InterPayList = {
  title: 'Credit/Debit Card',
  key: 'AW_CARD',
  src: '/icon/icon-credit.svg',
  listTips: 'Some banks may charge overseas transaction fees, please confirm with your issuing bank for details.',
  remind: '(May Charge 3%)',
};

export const idealPay: InterPayList = {
  title: 'iDEAL',
  key: 'IDEAL',
  src: '/icon/icon-idealpay.svg',
  country: ['NL'],
  currencys: {
    [CountryCode.DE]: ['EUR'],
    [CountryCode.FR]: ['EUR'],
    [CountryCode.IT]: ['EUR'],
    [CountryCode.NL]: ['EUR'],
    [CountryCode.BE]: ['EUR'],
    [CountryCode.LU]: ['EUR'],
    [CountryCode.IE]: ['EUR'],
    [CountryCode.ES]: ['EUR'],
    [CountryCode.PT]: ['EUR'],
    [CountryCode.AT]: ['EUR'],
    [CountryCode.FI]: ['EUR'],
    [CountryCode.LT]: ['EUR'],
    [CountryCode.LV]: ['EUR'],
    [CountryCode.EE]: ['EUR'],
    [CountryCode.SK]: ['EUR'],
    [CountryCode.SI]: ['EUR'],
    [CountryCode.GR]: ['EUR'],
    [CountryCode.MT]: ['EUR'],
    [CountryCode.VA]: ['EUR'],
    [CountryCode.GI]: ['EUR'],
    [CountryCode.BA]: ['EUR'],
    [CountryCode.MK]: ['EUR'],
    [CountryCode.ME]: ['EUR'],
    [CountryCode.RS]: ['EUR'],
    [CountryCode.BG]: ['EUR'],
    [CountryCode.RO]: ['EUR'],
    [CountryCode.AL]: ['EUR'],
    [CountryCode.HR]: ['EUR'],
    [CountryCode.SM]: ['EUR'],
    [CountryCode.BY]: ['EUR'],
    [CountryCode.RU]: ['EUR'],
    [CountryCode.UA]: ['EUR'],
    [CountryCode.MD]: ['EUR'],
    [CountryCode.CH]: ['EUR'],
    [CountryCode.PL]: ['EUR'],
    [CountryCode.CZ]: ['EUR'],
    [CountryCode.HU]: ['EUR'],
    [CountryCode.LI]: ['EUR'],
    [CountryCode.MC]: ['EUR'],
    [CountryCode.SE]: ['EUR'],
    [CountryCode.NO]: ['EUR'],
    [CountryCode.IS]: ['EUR'],
    [CountryCode.DK]: ['EUR'],
    [CountryCode.FO]: ['EUR'],
    [CountryCode.AD]: ['EUR'],
  },
  min: 0.01,
  max: Infinity,
  paramsKey: 'ideal',
  quotaCurrencys: 'EUR',
};

export const isSafari = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return 'ApplePaySession' in window;
};

export const newPayList: InterPayList[] = [idealPay];

export const platformPayments = (countryCode?: string) =>
  [
    {
      title: 'Pay',
      key: 'APPLE_PAY',
      src: '/icon/icon-apple-pay.svg',
      domName: 'applePayButton',
      hide: !isSafari(),
    },
    {
      title: 'Pay',
      key: 'GOOGLE_PAY',
      src: '/icon/icon-google.svg',
      domName: 'googlePayButton',
      hide: countryCode === 'CN',
    },
  ] as any;

// 新西兰元 NZD

const NZDPayList: InterPayList[] = [
  {
    title: 'WeChat Pay',
    key: 'AW_WECHAT',
    src: '/icon/icon-wechat.svg',

    paramsKey: 'wechatpay',
  },
  {
    title: 'Alipay',
    key: 'AW_ALIPAY',
    src: '/icon/icon-alipay.svg',
    paramsKey: 'alipaycn',
  },
  alipayHkPay,
  {
    title: 'Bank Transfer',
    key: 'BankTransfer',
    src: '/icon/icon-bank.svg',
    tag: 'Recommend',
  },
  unionInterpay,
  {
    title: 'Credit/Debit Card',
    key: 'AW_CARD',
    src: '/icon/icon-credit.svg',
    listTips: 'Some banks may charge overseas transaction fees, please confirm with your issuing bank for details.',
    remind: '(May Charge 3%)',
  },
  {
    title: 'Klarna',
    key: 'KLARNA',
    src: '/icon/icon-klarna.svg',
  },
  atomeInterPay,
  idealPay,
];
// 海外学生 人民币CNY
const CNYPayList: InterPayList[] = [
  {
    title: 'WeChat Pay',
    key: 'AW_WECHAT',
    src: '/icon/icon-wechat.svg',

    paramsKey: 'wechatpay',
  },
  {
    title: 'Alipay',
    key: 'AW_ALIPAY',
    src: '/icon/icon-alipay.svg',
    paramsKey: 'alipaycn',
  },
  alipayHkPay,
  {
    title: 'Bank Transfer',
    key: 'BankTransfer',
    src: '/icon/icon-bank.svg',
    tag: 'Recommend',
  },
  {
    title: 'Credit/Debit Card',
    key: 'AW_CARD',
    src: '/icon/icon-credit.svg',
    listTips: 'Some banks may charge overseas transaction fees, please confirm with your issuing bank for details.',
    remind: '(May Charge 3%)',
  },
  {
    title: 'Klarna',
    key: 'KLARNA',
    src: '/icon/icon-klarna.svg',
  },
  atomeInterPay,
  idealPay,
];
// 海外学生 美元USD
const USDPayList: InterPayList[] = [
  {
    title: 'WeChat Pay',
    key: 'AW_WECHAT',
    src: '/icon/icon-wechat.svg',
    paramsKey: 'wechatpay',
  },
  {
    title: 'Alipay',
    key: 'AW_ALIPAY',
    src: '/icon/icon-alipay.svg',
    paramsKey: 'alipaycn',
  },
  alipayHkPay,
  {
    title: 'Bank Transfer',
    key: 'BankTransfer',
    src: '/icon/icon-bank.svg',
    tag: 'Recommend',
  },
  {
    title: 'Klarna',
    key: 'KLARNA',
    src: '/icon/icon-klarna.svg',
  },
  paypalInterPay,
  atomeInterPay,
  creditCardPay,
  idealPay,
];

// 海外学生 澳币AUD
const AUDPayList: InterPayList[] = [
  {
    title: 'WeChat Pay',
    key: 'AW_WECHAT',
    src: '/icon/icon-wechat.svg',

    paramsKey: 'wechatpay',
  },
  {
    title: 'Alipay',
    key: 'AW_ALIPAY',
    src: '/icon/icon-alipay.svg',
    paramsKey: 'alipaycn',
  },
  alipayHkPay,
  {
    title: 'Bank Transfer',
    key: 'BankTransfer',
    src: '/icon/icon-bank.svg',
    tag: 'Recommend',
  },
  {
    title: 'Credit/Debit Card',
    key: 'AW_CARD',
    src: '/icon/icon-credit.svg',
    listTips: 'Some banks may charge overseas transaction fees, please confirm with your issuing bank for details.',
    remind: '(May Charge 3%)',
  },
  {
    title: 'Klarna',
    key: 'KLARNA',
    src: '/icon/icon-klarna.svg',
  },
  atomeInterPay,
  idealPay,
];

// 支付货币 对应 支付方式
const PayListSwitch = {
  NZD: NZDPayList,
  CNY: CNYPayList,
  USD: USDPayList,
  AUD: AUDPayList,
  DEFAULT: [],
};
interface CurrencyItem {
  value: string;
  label: string;
}
const currency: CurrencyItem[] = [
  {
    value: '12-3678-0010070-00',
    label: 'NZD',
  },
  {
    value: '27005587-USD-33',
    label: 'USD',
  },
  {
    value: '27005569-AUD-37',
    label: 'AUD',
  },
  {
    value: '27005588-CNY-32',
    label: 'CNY',
  },
  {
    value: '27005570-CAD-43',
    label: 'CAD',
  },
  {
    value: '27005586-SGD-34',
    label: 'SGD',
  },
  {
    value: '27005572-EUR-41',
    label: 'EUR',
  },
  {
    value: '27005583-GBP-37',
    label: 'GBP',
  },
  {
    value: '27005585-JPY-35',
    label: 'JPY',
  },
  {
    value: '27005584-HKD-36',
    label: 'HKD',
  },
];

const AllPayList: InterPayList[] = [
  {
    title: 'WeChat Pay',
    key: 'AW_WECHAT',
    src: '/icon/icon-wechat.svg',
  },
  {
    title: 'Alipay',
    key: 'AW_ALIPAY',
    src: '/icon/icon-alipay.svg',
  },
  alipayHkPay,
  {
    title: 'Bank Transfer',
    key: 'BankTransfer',
    src: '/icon/icon-bank.svg',
    tag: 'Recommend',
  },
  {
    title: 'Credit/Debit Card',
    key: 'AW_CARD',
    src: '/icon/icon-credit.svg',
    listTips: 'Some banks may charge overseas transaction fees, please confirm with your issuing bank for details.',
    remind: '(May Charge 3%)',
  },
  unionInterpay,
  InstallmentInterPay,
  paypalInterPay,
  venmoInterpay,
  cashAppPayInterpay,
  payNowInterPay,
  idealPay,
];
// 用户所在关键国家,用于billinginof收集
export enum BillingInfoCustomCountry {
  US = 'US',
  AU = 'AU',
  CA = 'CA',
  SG = 'SG',
  NZ = 'NZ',
  GB = 'GB',
}
export { AllPayList, PayListSwitch, currency };
