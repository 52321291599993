import usePaymentStore from '@/store/usePaymentStore';
import { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';

const useDeviceType = () => {
  const { width } = useWindowSize();
  const payStore = usePaymentStore();
  const [isMobile, setIsMobile] = useState(undefined as boolean | undefined);

  useEffect(() => {
    setIsMobile(width <= 480);
    payStore.setIsMobile(width <= 480);
  }, [width]);

  return isMobile;
};

export default useDeviceType;
