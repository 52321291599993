/**
 * 主要管理消息展示逻辑
 */
import { Coupon, OrderProps, ActionEnum, InterPayList, OrderType } from '@/api/types/payment';
import { platformPayments } from '@/utils/dictionary';
import create from 'zustand';
import { combine } from 'zustand/middleware';

export interface PaymentContextType {
  countryCode?: string;
  orderInfo?: OrderProps;
  paymentMethod?: string;
  pageLoading?: boolean;
  pageLoadingOwner?: string;
  specialCountryCode?: string;
  curStudent?: any;
  coupon?: Coupon;
  subscriptionPriceInfo?: any;
  pageLoadingOwnerCollections?: string[];
  billingCountrycode?: string;
  formValidator?: {
    submitting: boolean;
    billingCompleted: boolean;
    popupLoading: boolean;
  };
  isMobile?: boolean;
  cardForm?: { cardNumber: boolean; expiry: boolean; cvc: boolean };
  commonPayList?: InterPayList[];
  orderType?: OrderType;
  paymentStatus?: string;
  addressListVisible?: boolean;
  subscribRes?: any;
}

const initialState: PaymentContextType = {
  pageLoading: false,
  cardForm: {
    cardNumber: false,
    expiry: false,
    cvc: false,
  },
  addressListVisible: false,
};

let timer: any = null;

export const getConfirmDisabled = (state: PaymentContextType) => {
  const { formValidator, cardForm, paymentMethod, isMobile } = state;
  let isBillingPage = false;
  if (typeof window !== 'undefined') {
    isBillingPage = /cashier-desk\/billing/.test(window.location.href);
  }
  const { submitting, billingCompleted, popupLoading } = (formValidator as keyof typeof formValidator) || {};

  const allComplete = cardForm
    ? Object.keys(cardForm).every((key) => {
        return cardForm[key as keyof typeof cardForm];
      })
    : false;

  const confirmDisabled = (() => {
    // 非移动端并卡支付
    if (paymentMethod === 'AW_CARD' && !isMobile) {
      return submitting || !allComplete || popupLoading || (isBillingPage && billingCompleted);
    }
    // 移动端并且是卡支付
    if (paymentMethod === 'AW_CARD' && isMobile) {
      return !allComplete;
    }
    // 移动端校验
    if (isMobile) {
      return popupLoading;
    }
    return submitting || popupLoading || (isBillingPage && billingCompleted);
  })();

  const iframeClickEable = () => {
    if (typeof document === 'undefined') {
      return;
    }

    if (timer) {
      clearInterval(timer);
    }

    timer = setInterval(() => {
      const findOne = platformPayments().find((item: any) => item.key === paymentMethod);

      const iframe = document.querySelector(`#${findOne?.domName} iframe`) as HTMLIFrameElement;

      if (iframe) {
        Object.assign(iframe.style, {
          'pointer-events': !confirmDisabled ? 'auto' : 'none',
        });
        clearInterval(timer);
      }
    }, 500);
  };

  if (['APPLE_PAY', 'GOOGLE_PAY'].includes(paymentMethod || '')) {
    iframeClickEable();
  }

  return confirmDisabled;
};

export const getConfirmNextDisabled = (state: PaymentContextType) => {
  const { formValidator, paymentMethod } = state;

  const { submitting, billingCompleted, popupLoading } = (formValidator as keyof typeof formValidator) || {};

  const confirmNextDisabled = (() => {
    return submitting || billingCompleted || popupLoading;
  })();

  const iframeClickEable = () => {
    if (typeof document === 'undefined') {
      return;
    }

    if (timer) {
      clearInterval(timer);
    }

    timer = setInterval(() => {
      const findOne = platformPayments().find((item: any) => item.key === paymentMethod);

      const iframe = document.querySelector(`#${findOne?.domName} iframe`) as HTMLIFrameElement;

      if (iframe) {
        Object.assign(iframe.style, {
          'pointer-events': !confirmNextDisabled ? 'auto' : 'none',
        });
        clearInterval(timer);
      }
    }, 500);
  };

  if (['APPLE_PAY', 'GOOGLE_PAY'].includes(paymentMethod || '')) {
    iframeClickEable();
  }

  return confirmNextDisabled;
};

const usePaymentStore = create(
  combine<
    PaymentContextType,
    {
      [key: string]: any;
    }
  >(initialState, (set) => {
    return {
      [ActionEnum.SET_COUNTRY_CODE]: (countryCode: string) => {
        set(() => {
          return { countryCode };
        });
      },
      [ActionEnum.SET_ADDRESS_LIST_VISIBLE]: (addressListVisible: boolean) => {
        set(() => {
          return { addressListVisible };
        });
      },
      [ActionEnum.SET_PAGE_LOADING]: (pageLoading: boolean) => {
        set(() => {
          return { pageLoading };
        });
      },
      [ActionEnum.SET_PAYMENT_METHOD]: (paymentMethod: string) => {
        set(() => {
          return { paymentMethod };
        });
      },
      [ActionEnum.SET_ORDERINFO]: (orderInfo: OrderProps) => {
        set(() => {
          return { orderInfo };
        });
      },
      [ActionEnum.SET_SUBSCRIBE_INFO]: (subscribRes: any) => {
        set(() => {
          return { subscribRes };
        });
      },
      [ActionEnum.SET_SPECIAL_COUNTRY_CODE]: (specialCountryCode: string) => {
        set(() => {
          return { specialCountryCode };
        });
      },
      [ActionEnum.SET_CURRENT_STUDENT_INFO]: (curStudent: string) => {
        set(() => {
          return { curStudent };
        });
      },
      [ActionEnum.SET_COUPON_INFO]: (coupon: any) => {
        set(() => {
          return { coupon };
        });
      },
      [ActionEnum.SET_SUBSCRIPTION_PRICE_INFO]: (subscriptionPriceInfo: any) => {
        set(() => {
          return { subscriptionPriceInfo };
        });
      },
      [ActionEnum.SET_BILLING_COUNTRY]: (billingCountrycode: string) => {
        set(() => {
          return { billingCountrycode };
        });
      },
      [ActionEnum.SET_COMMON_PAYLIST]: (commonPayList: InterPayList[]) => {
        set(() => {
          return { commonPayList };
        });
      },
      setIsMobile: (isMobile: boolean) => {
        set(() => {
          return { isMobile };
        });
      },
      setOrderType: (orderType: OrderType) => {
        set(() => {
          return { orderType };
        });
      },
      setPaymentStatus: (paymentStatus: string) => {
        set(() => {
          return { paymentStatus };
        });
      },
      [ActionEnum.SET_FORM_VALIDATOR]: (formValidator: any) => {
        set((state) => {
          return { formValidator: { ...state.formValidator, ...formValidator } } as any;
        });
      },
      [ActionEnum.SET_CARD_FORM]: (cardForm: any) => {
        set((state) => {
          return { cardForm: { ...state.cardForm, ...cardForm } } as any;
        });
      },
    };
  }),
);

export default usePaymentStore;
